import React, { useEffect, useRef } from 'react';

type Props = {
  letter: string;
  onClick: () => void;
  type?: string; 
  focus?: boolean;
  
};

const LetterButton: React.FC<Props> = ({ letter, onClick, type, focus }) => {
  let className = `letter-btn letter-${letter.replace(/\s+/g, '').toLowerCase()}`;
  if (letter === 'Q' && type) {
    className += `-${type.toLowerCase()}`;
  }

  if (focus) {
    className += ' focus';
  }

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focus && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [focus]);

  return <button ref={buttonRef} className={className} onClick={onClick}>{letter}</button>;
};



export default LetterButton;