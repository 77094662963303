import React, { useState, useEffect } from "react";
import "./App.css";
import LetterButton from "./components/LetterButton";
import { LETTERS_MEANINGS } from "./common/constants";
import { useSpring, animated } from "react-spring";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  const [selectedShortMeaning, setSelectedShortMeaning] = useState<string | null>(null);
  const [selectedDetailedMeaning, setSelectedDetailedMeaning] = useState<string | null>(null);
  const [showFade, setShowFade] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [focusedLetter, setFocusedLetter] = useState<string | null>(null);

  useEffect(() => {
    const selectRandomLetter = () => {
      const randomIndex = Math.floor(Math.random() * LETTERS_MEANINGS.length);
      const randomLetter = LETTERS_MEANINGS[randomIndex];
      setSelectedShortMeaning(randomLetter.meaning.shortMeaning);
      setSelectedDetailedMeaning(randomLetter.meaning.detailedMeaning);
      setButtonClicked(true);
      setFocusedLetter(`${randomLetter.letter}-${randomLetter.meaning.shortMeaning}`);
    };
    selectRandomLetter();
  }, []);

  const fadeAnimation = useSpring({
    opacity: showFade ? 1 : 0,
    from: { opacity: 0 },
    reset: true,
    delay: showFade ? 200 : 0,
    config: {
      duration: 300,
    },
  });

  const handleButtonClick = (
    shortMeaning: string,
    detailedMeaning: string,
    letter: string
  ) => {
    setShowFade(false);
    setSelectedShortMeaning(shortMeaning);
    setSelectedDetailedMeaning(detailedMeaning);
    setFocusedLetter(`${letter}-${shortMeaning}`);
    setTimeout(() => setShowFade(true), 20);
  };

  return (
    <div className="wrapper">
      <div className="App ">
        <Header />
        <div className="middle-section ">
          {LETTERS_MEANINGS.map((entry) => (
            <LetterButton
              key={`${entry.letter}-${entry.meaning.shortMeaning}`}
              letter={entry.letter}
              type={
                entry.meaning.shortMeaning === "Queer"
                  ? "queer"
                  : entry.meaning.shortMeaning === "Questioning"
                  ? "questioning"
                  : undefined
              }
              onClick={() => {
                handleButtonClick(
                  entry.meaning.shortMeaning,
                  entry.meaning.detailedMeaning,
                  entry.letter
                );
                setButtonClicked(true);
              }}
              focus={`${entry.letter}-${entry.meaning.shortMeaning}` === focusedLetter}
            />
          ))}
        </div>
        {buttonClicked && (
          <div className="content-container">
            <animated.div style={fadeAnimation as any}>
              {selectedShortMeaning && (
                <h3 className="letter-meaning">{selectedShortMeaning}</h3>
              )}
              {selectedDetailedMeaning && (
                <p className="letter-description">{selectedDetailedMeaning}</p>
              )}
            </animated.div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}

export default App;