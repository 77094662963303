import { LetterEntry } from "./types";

export const LETTERS_MEANINGS: LetterEntry[] = [
  {
    letter: "L",
    meaning: {
      shortMeaning: "Lesbian",
      detailedMeaning: "A person who identifies as a woman and experiences primary sexual and affectional attraction toward people of the same gender. It's important to note that some nonbinary individuals also identify as lesbians, often because they have a connection to womanhood and are primarily attracted to women."
    },
  },
  {
    letter: "G",
    meaning: {
      shortMeaning: "Gay",
      detailedMeaning: "Refers to individuals who have an affectional or sexual attraction to members of the same gender. This term is synonymous with homosexuality."
    },
  },
  {
    letter: "B",
    meaning: {
      shortMeaning: "Bisexual",
      detailedMeaning: "A person whose primary sexual and affectional orientation is toward people of the same and other genders, or towards people regardless of their gender. It's worth noting that some people may use 'bisexual' and 'pansexual' interchangeably."
    },
  },
  {
    letter: "T",
    meaning: {
      shortMeaning: "Transgender",
      detailedMeaning: "An adjective used as an umbrella term to describe individuals whose internal gender identity differs from the sex assigned to them at birth. Transgender individuals may include those who identify as a gender other than woman or man, such as non-binary, genderqueer, genderfluid, and more."
    },
  },
  {
    letter: "Q",
    meaning: {
      shortMeaning: "Queer",
      detailedMeaning: "An umbrella term used to describe gender, sexual, or romantic orientations that fall outside societal norms. Historically, 'queer' has been used pejoratively against the LGBTQ+ community, but some individuals have reclaimed it as a way of celebrating their non-conformity to social norms."
    },
  },
  {
    letter: "Q",
    meaning: {
      shortMeaning: "Questioning",
      detailedMeaning: "The process of exploring one's own gender identity, gender expression, and/or sexual orientation. Some individuals may also use this term to describe their identity within the LGBTQIA community."
    },
  },
  {
    letter: "I",
    meaning: {
      shortMeaning: "Intersex",
      detailedMeaning: "A wide range of natural body variations that do not fit neatly into conventional definitions of male or female. Intersex variations may include differences in chromosome compositions, hormone concentrations, and external and internal characteristics."
    },
  },
  {
    letter: "P",
    meaning: {
      shortMeaning: "Pansexual",
      detailedMeaning: "A term used to describe people who have romantic, sexual, or affectional desires for individuals of all genders and sexes. Pansexuality is inclusive of all gender identities."
    },
  },
  {
    letter: "2S",
    meaning: {
      shortMeaning: "Two-Spirited",
      detailedMeaning: "An Indigenous term that reflects certain Native American cultures' views of gender and spirituality. It signifies a person embodying both masculine and feminine spirits and often involves unique societal roles."
    },
  },
  {
    letter: "A",
    meaning: {
      shortMeaning: "Asexual",
      detailedMeaning: "Refers to individuals who may not experience sexual attraction or have limited sexual desire. Asexuality doesn't preclude the possibility of experiencing other forms of attraction, such as romantic or emotional connections."
    },
  },
];
