function Footer() {

  const currentYear = new Date().getFullYear();


  return (
    <footer className="footer">
      <p>VB Solutions Ltd. © {currentYear} </p>
    </footer>
  );
}
export default Footer;
