function Header() {
  return (
    <header className="header">
      <h1>
        <span className="normal-text">Explore the alphabet of </span> 
        <span className="bold-text  ">PRIDE&IDENTITY</span>
      </h1>
  
    </header>
  );
}
export default Header;